export class CustomerDetails {
    id?: string;
    name?: string;
    phone?: string;
    email?: string;
    remark?: string;

    billingCompanyName?: string;
    billingName?: string;
    billingStreetNumber?: string;
    billingZipCity?: string;

    adressCompanyName?: string;
    adressName?: string;
    adressStreetNumber?: string;
    adressZipCity?: string;

    pickUpPoint?: string;
    streetNumber?: string;
    zipCity?: string;

    products?: Products[];
}

export class Products{
    product_id?: number;
    name?: string;
    price?: string;
    total?: string;
    total_tax?: string;
    total_with_tax?: string;
    quantity?: number;
    total_price?: number;
    brand?: string;
}
