import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = environment.API_ENDPOINT;
  path = 'api/v1/product';

  constructor(private http: HttpClient) { }

  getProductDetails(ids: string): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/${this.path}/${ids}`);
  }
  
}
